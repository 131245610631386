import React from 'react'
import { motion } from "framer-motion";
import { FaInstagram } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa6";

const TeamCard = ({imageUrl, name, role, linkedin, instagram}) => {
  return (
    <motion.div 
        className="bg-white rounded-lg px-4 py-2 sm:ml-5 sm:mr-5 sm:mt-5 sm:mb-5 ml-2 mr-2 mt-2 mb-2 w-[180px] drop-shadow-md"
        whileHover={{ scale: 1.1 }}
    >
        <div className="w-full flex justify-center">
            <img 
                alt="officer_picture" 
                className="rounded-full w-[100px] h-[100px]"
                src={imageUrl}
            />
        </div>
        <div className="text-center">
            <h2 className="font-extrabold mt-3 text-sm md:text-md text-[#510CA9]">{name}</h2>
            <h2 className="font-normal text-xs md:text-sm text-[#510CA9]">{role}</h2>
            <div className="flex justify-center mt-2" >
                {instagram.length != 0 ? 
                    <motion.a href={instagram} target="_blank" whileHover={{ scale: 1.1 }}>
                        <FaInstagram className="mr-2 text-lg text-white bg-gradient-to-r from-[#DD2A7B] to-[#F58529] rounded-md" />
                    </motion.a>
                : 
                    ""
                }
                {linkedin.length != 0 ?
                    <motion.a href={linkedin} target="_blank" whileHover={{ scale: 1.1 }}>
                        <FaLinkedin className="text-lg text-[#0072b1]"/>
                    </motion.a>
                :
                    ""
                }
            </div>
        </div>
    </motion.div>
  )
}

export default TeamCard
