import React from 'react'
import { motion } from 'framer-motion';

const AboutUs = () => {
  return (
    <motion.section 
        id="aboutus" className="scroll-mt-0 scroll-smooth"
        initial={{ opacity: 0, y: 25 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        transition={{ duration: 0.6, delay: 0.5 }}
    >
      <div className="w-screen py-8">
        <h1 className="text-center text-3xl sm:text-4xl lg:text-6xl font-extrabold text-[#742C7F] tracking-wider">Who are we?</h1>
        <div className="px-11">
            <p className="text-center text-md sm:text-xl lg:text-3xl mt-6 font-medium text-[#7B66FF]">
                Our mission is to establish a <span className="font-extrabold text-[#510CA9]">multidisciplinary community</span> where students can 
                collaborate, drawing from diverse fields such as <span className="font-extrabold text-[#510CA9]">healthcare, engineering, 
                and business, to transform their ideas into tangible ventures</span>. Our primary goal is to
                empower students to turn their <span className="font-extrabold text-[#510CA9]">entrepreneurial visions</span> into reality by providing 
                them with the necessary support, resources, and guidance. By fostering a culture of creativity and collaboration, we aim to cultivate the next generation of tech
                innovators and leaders who will drive positive change in their respective fields.
            </p>
        </div>
      </div>
    </motion.section>
  )
}

export default AboutUs;
