import React from 'react'
import TeamCard from './TeamCard';
import { motion } from "framer-motion"
import { officerInformation } from '../constants';
const OurTeam = () => {
  return (
    <motion.section 
        id="ourteam" 
        className="w-screen bg-gradient-to-b from-[#7657A0] to-[#742C7F] py-6 scroll-mt-0 scroll-smooth" 
        initial={{ opacity: 0, y: 25 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        transition={{ duration: 0.6, delay: 0.5 }}
    >
        <div>
            <h1 className="text-center text-3xl sm:text-4xl lg:text-6xl font-extrabold text-white tracking-wider">Our Team</h1>
        </div>
        <div className="w-screen flex justify-center flex-wrap px-2 md:px-5">
            {officerInformation.map(({name, imageUrl, role, linkedin, instagram}) => (
                <TeamCard name={name} imageUrl={imageUrl} role={role} linkedin={linkedin} instagram={instagram} />
            ))}
        </div>
    </motion.section>
  )
}

export default OurTeam;
