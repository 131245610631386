import meghna from "../src/assets/officers/meghna.png";
import likitha from "../src/assets/officers/likitha.png";
import ananya from "../src/assets/officers/ananya.png";
import kshiti from "../src/assets/officers/kshiti.png";
import datta from "../src/assets/officers/datta.png";
import atharv from "../src/assets/officers/atharv.png";
import miranda from "../src/assets/officers/miranda.png";
import aanvi from "../src/assets/officers/aanvi.png";
import priya from "../src/assets/officers/priya.png";
import riya from "../src/assets/officers/riya.png";
import cristian from "../src/assets/officers/cristian.png";
import avi from "../src/assets/officers/avi.png";
import keshav from "../src/assets/officers/keshav.png";

export const officerInformation = [
    {
        name: "Meghna Yeladandi",
        imageUrl: meghna, 
        role: "Co-President",
        linkedin: "https://www.linkedin.com/in/meghna-yeladandi-10a44b218/",
        instagram: "https://www.instagram.com/meghnayeladandi?igsh=ajRucnJ0bnh3bHNy",
    },
    {
        name: "Likitha Joneboina",
        imageUrl: likitha, 
        role: "Co-President",
        linkedin: "https://www.linkedin.com/in/likithaj-b573b0245/",
        instagram: "",
    },
    {
        name: "Ananya Gardas",
        imageUrl: ananya, 
        role: "Internal Affairs Officer",
        linkedin: "https://www.linkedin.com/in/ananya-gardas-b3a164278/",
        instagram: "https://www.instagram.com/ananyagardas/?next=%2F",
    },
    {
        name: "Kshiti Kangovi",
        imageUrl: kshiti, 
        role: "Internal Affairs Officer",
        linkedin: "https://www.linkedin.com/in/kshitikangovi/",
        instagram: "https://www.instagram.com/katykangovi?igsh=anIxNmwwZjN4MHQ0",
    },
    {
        name: "Datta Mullapudi",
        imageUrl: datta, 
        role: "External Affairs Officer",
        linkedin: "https://www.linkedin.com/in/dattam327/",
        instagram: "https://www.instagram.com/datta.mullapudi?igsh=MWhhM2QwajF4Z25zeA==",
    },
    {
        name: "Atharv Chagi",
        imageUrl: atharv, 
        role: "Finance Officer",
        linkedin: "https://www.linkedin.com/in/atharv-chagi-b04575234/",
        instagram: "https://www.instagram.com/atharvchagi?igsh=MXB1N3JnbGFnZjJrOA==",
    },
    {
        name: "Miranda Martin",
        imageUrl: miranda, 
        role: "Media + Marketing Officer",
        linkedin: "https://www.linkedin.com/in/mirandamartintovar/",
        instagram: "https://www.instagram.com/mirandaamartinn?igsh=bTVtazBqa2g5Mm41",
    },
    {
        name: "Aanvi Krishna",
        imageUrl: aanvi, 
        role: "Media + Marketing Officer",
        linkedin: "https://www.linkedin.com/in/aanvi-krishna/",
        instagram: "https://www.instagram.com/aanvikrishna26?igsh=MTg1OHM3MjdsdWdxeg==",
    },
    {
        name: "Priyadarsi Mishra",
        imageUrl: priya, 
        role: "Web Development Officer",
        linkedin: "https://www.linkedin.com/in/priyadarsi-mishra/",
        instagram: "https://www.instagram.com/stephaplash1234?igsh=NWIzdmEzdXpwNWRs==",
    },
    {
        name: "Riya Patel",
        imageUrl: riya, 
        role: "Web Development Officer",
        linkedin: "https://www.linkedin.com/in/riya-patel-11162a314/",
        instagram: "https://www.instagram.com/riya_patel.0201?igsh=MW1wNXEwaGswMXV3aQ==",
    },
    {
        name: "Cristian Aponte",
        imageUrl: cristian, 
        role: "Workshop Management: Engineering",
        linkedin: "https://www.linkedin.com/in/cristian-aponte/",
        instagram: "https://www.instagram.com/cristian.apontee?igsh=MWp0ZWM0enh2eTd4Nw==",
    },
    {
        name: "Avi Vedala",
        imageUrl: avi, 
        role: "Workshop Management: Engineering",
        linkedin: "https://www.linkedin.com/in/avyay-vedala",
        instagram: "https://www.instagram.com/avyay_vedala?igsh=MWY4aGUxeGg4Njkxeg==",
    },
    {
        name: "Keshav Laxminarasimhan",
        imageUrl: keshav, 
        role: "Workshop Management: Healthcare",
        linkedin: "https://www.linkedin.com/in/keshav-laxminarasimhan-971ba7255/",
        instagram: "https://www.instagram.com/keshav_lax?igsh=Nmg4eXRveXJwbTYw",
    }
]