import React, { useState } from 'react'

const ResourcesTab = ({question, answer}) => {
    const [isOpen, setIsOpen] = useState(false);

    // Toggle function for opening/closing section
    const toggleOpen = () => {
        setIsOpen(!isOpen);
    };

  return (
    <div className="p-3">
      <div className="flex justify-between items-center cursor-pointer p-1 border-b-2 border-[#dddddd]" onClick={toggleOpen}>
        <div>
            <h1 className="text-sm sm:text-md lg:text-xl font-extrabold tracking-wider text-[#411647]">{question}</h1>
        </div>
        <div>
            <h2 className="font-md">{isOpen ? '▲' : '▼'}</h2>
        </div>
      </div>
      {isOpen && (
        <div className="text-sm sm:text-md lg:text-lg font-medium text-[#7657A0] p-2">
          <p>{answer}</p>
        </div>
      )}
    </div>
  )
}

export default ResourcesTab
