import React from 'react'
import BGImage from "../assets/background.png";
import { motion } from "framer-motion";

const Hero = () => {
  return (
    <section id="home" className="w-screen bg-[#7657A0] z-0 relative">
        <img
            src={BGImage}
            className="w-screen h-100% bg-purple-400 z-0"
        />
        <div className="z-10 w-screen absolute top-[20%]">
            <motion.h1 
                className="z-10 text-3xl sm:text-5xl md:text-6xl lg:text-8xl text-nowrap text-center font-extrabold text-white"
                initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.6, delay: 0.2 }}
            >
                Texas A&M
            </motion.h1>
            <motion.h1  
                className="z-10 text-3xl sm:text-5xl md:text-6xl lg:text-8xl md:mt-5 text-nowrap text-center text-[#D196FF] font-extrabold tracking-wider"
                initial={{ opacity: 0, y: 25 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.6, delay: 0.5 }}
            >
                TechVenture Initiative
            </motion.h1>
            <motion.h1 
                className="z-10 text-[12px] sm:text-sm md:text-md lg:text-lg mt-4 text-wrap text-center text-white italic font-semibold tracking-wider"
                initial={{ opacity: 0, y: 10 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.6, delay: 0.5 }}
            >
                Nurturing innovation and startup culture among Texas A&M University students 
                passionate about technology.
            </motion.h1>
        </div>
    </section>
  )
}

export default Hero
