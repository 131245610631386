import React from 'react'
import { SiGoogledocs } from "react-icons/si";
import { FaArrowCircleRight } from "react-icons/fa";
import { motion } from "framer-motion"

const JoinUs = () => {
  return (
    <motion.section 
        id="joinus" 
        className="w-screen py-8 scroll-mt-0 scroll-smooth"
        initial={{ opacity: 0, y: 25 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        transition={{ duration: 0.6, delay: 0.5 }}
    >
        <div>
            <h1 className="text-center text-3xl sm:text-4xl lg:text-6xl font-extrabold text-[#742C7F] tracking-wider">Join Us!</h1>
        </div>
        <div className="mt-5 w-screen flex justify-center flex-wrap px-2 md:px-5 drop-shadow-lg">
            <div className="w-[500px] p-5 border-2 bg-gradient-to-b from-[#742C7F] to-[#7657A0] rounded-lg">
                <h1 className="font-extrabold text-xl text-wrap text-white text-center tracking-wide">Project Proposal & Project Management Application</h1>
                <p className="text-center text-white font-normal mt-2">
                    The application below is to propose a project idea and/or become the project manager. More details are provided below about the application.
                </p>
                <div className="mt-8 flex flex-col">
                    <motion.a href="https://docs.google.com/document/d/1iPxLGKyJgJ2-bbvZIVR7k9FoR92D9MpiepgM25LNqrs/edit?usp=sharing" target="_blank" 
                        className="bg-[#7657A0] p-3 rounded-xl ring-2 ring-purple-300"
                        whileHover={{ scale: 1.05 }}
                    >
                        <div className="flex justify-center items-center">
                            <SiGoogledocs className="text-[#96efff] mr-2 text-xl font-extrabold"/> 
                            <h2 className="text-lg font-extrabold text-white">Project Proposal Requirements</h2>
                        </div>
                    </motion.a>
                    <motion.a href="https://docs.google.com/document/d/1j8gsGdh-my_mVZKD7k1BiqggZmeic1sOBtBzgZArPPw/edit?usp=sharing" target="_blank" 
                        className="mt-5 bg-[#7657A0] p-3 rounded-xl ring-2 ring-purple-300"
                        whileHover={{ scale: 1.05 }}
                    >
                        <div className="flex justify-center items-center">
                            <SiGoogledocs className="text-[#96efff] mr-2 text-xl font-extrabold"/> 
                            <h2 className="text-lg font-extrabold text-white">Project Manager Requirements</h2>
                        </div>
                    </motion.a>
                    <motion.a href="https://docs.google.com/forms/d/e/1FAIpQLSdm2bYwsaYEY8_2J1chLSL48vVQqftbyIvs-fXzU_kPvnDWfg/viewform?usp=sharing" target="_blank"
                        className="mt-5 bg-[#411647] p-3 rounded-xl ring-2 ring-purple-300"
                        whileHover={{ scale: 1.05 }}
                    >
                        <div className="flex justify-center items-center">
                            <h2 className="text-lg font-extrabold text-white">Application</h2>
                            <FaArrowCircleRight className="text-white ml-2 text-xl font-extrabold"/> 
                        </div>
                    </motion.a>
                </div>
            </div>
        </div>
    </motion.section>
  )
}

export default JoinUs;
