import react from "react";
import Nav from "../components/Nav";
import Hero from "../components/Hero";
import AboutUs from "../components/AboutUs";
import OurTeam from "../components/OurTeam";
import JoinUs from "../components/JoinUs";
import Footer from "../components/Footer";
import FAQs from "../components/FAQs";

const LandingPage = () => {
    return (
        <div className="scroll-smooth container">
            <div class="bg-[#7657A0] text-center py-2 lg:px-4 w-screen items-center">
                <div class="p-2 bg-indigo-800 items-center text-indigo-100 leading-none lg:rounded-full flex lg:inline-flex" role="alert">
                    <span class="flex rounded-full bg-indigo-500 uppercase px-2 py-1 text-xs font-bold mr-3">New</span>
                    <span class="font-semibold mr-2 text-left flex-auto">Company CEOs will be our Guest Speakers at our Meeting at ETB this Thursday, 6-7pm! Food will be provided.</span>
                </div>
            </div>

            <Nav />
            <section>
                <Hero />
            </section>
            <section>
                <AboutUs />
            </section>
            <section>
                <OurTeam />
            </section>
            <section>
                <JoinUs />
            </section>
            <section>
                <FAQs />
            </section>
            <section>
                <Footer />
            </section>
        </div>
    )
}

export default LandingPage;