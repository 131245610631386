import React from 'react'
import Logo from "../assets/logo.png";
import { motion } from "framer-motion"

const Nav = () => {
  return (
    <header className="px-3 py-3 w-screen bg-[#7657A0] z-10 scroll-mt-0 scroll-smooth">
        <nav className="flex justify-between items-center">
            <a href="/" className="px-2">
                <img 
                    src={Logo}
                    alt="logo"
                    width={90}
                    height={90}
                />
            </a>

            <ul className="flex justify-between gap-14 mr-5">
                <motion.a 
                    className="max-md:hidden px-6 py-3 bg-white rounded-lg 
                        text-xl font-extrabold text-[#7B66FF] ring-1 ring-purple-200 drop-shadow-md" 
                    href="#aboutus"
                    whileHover={{ scale: 1.1 }}
                >
                    About Us
                </motion.a>
                <motion.a className="max-md:hidden px-6 py-3 bg-white rounded-lg 
                        text-xl font-extrabold text-[#7B66FF] ring-1 ring-purple-200 drop-shadow-md" 
                    href="#ourteam"
                    whileHover={{ scale: 1.1 }}
                >
                    Our Team
                </motion.a>
                <motion.a 
                    className="max-md:hidden px-6 py-3 bg-white rounded-lg 
                        text-xl font-extrabold text-[#7B66FF] ring-1 ring-purple-200 drop-shadow-md" 
                    href="#faqs"
                    whileHover={{ scale: 1.1 }}
                >
                    FAQs
                </motion.a>
                <motion.a className="px-10 py-3 bg-gradient-to-r from-[#510CA9] to-[#7B66FF] 
                        rounded-lg text-xl font-extrabold text-white ring-1 ring-purple-400 drop-shadow-md" 
                    href="#joinus"
                    whileHover={{ scale: 1.1 }}
                    
                >
                    Join
                </motion.a>
            </ul>
        </nav>
    </header>
  )
}

export default Nav;
