import React from 'react'
import { motion } from 'framer-motion';
import ResourcesTab from './ResourcesTab';
import { FAQConstants } from '../FAQConstants';

const FAQs = () => {
  return (
    <motion.section 
        id="faqs" 
        initial={{ opacity: 0, y: 25 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        transition={{ duration: 0.6, delay: 0.5 }}
        className="w-screen bg-gradient-to-r from-[#7657A0] to-[#742C7F] py-6 scroll-mt-0 scroll-smooth flex justify-center items-center" 
    >
        <div className="w-3/5">
            <h1 className="text-center text-xl sm:text-2xl lg:text-5xl font-extrabold text-white tracking-wider">Frequently Asked Questions</h1>
            <div className="shadow bg-white p-3 mt-5 rounded-md">
              {FAQConstants.map(({question, answer, index}) => (
                  <ResourcesTab index={index} question={question} answer={answer} />
              ))}
            </div>
        </div>
    </motion.section>
  )
}

export default FAQs
