export const FAQConstants = [
    {
        question: "How can I become a member?",
        answer: "TVI offers two types of memberships! For those wishing to be a general member, we do not require applications to attend our General Meetings. However, those interested in being Project Managers or being on a project must complete an application."
    },
    {
        question: "How is this different from other project-based orgs?",
        answer: "Our Big Differentiator is that we are Multi-Disciplinary! On top of that we give you access to an advice bank (coming soon!) only TVI members can see, give you personal relationships with companies and guest collaborations and have mentors in the field to guide you in bringing your vision to life. Project Manager's (PM) get to choose who they want on their teams."
    },
    {
        question: "What if the type of project I have an idea for isn't my realm of expertise?",
        answer: "No problem at all! TVI is multi-disciplinary and as a PM you will be able to make your own team of students of different majors/skills that will work on the project together. On top of that, our Workshop Officers are there for you! We have workshop offucers for different sectors and you can reach out to them if you need help."
    },
    {
        question: "What is the structure for workshops?",
        answer: "TVI offers General Meetings + Workshops! General Meetings are every other week and open to everyone. We will have guest speakers, company panels, industry insights, skill tutorials, etc. during this time. Workshops are every week and open to members working on projects. This is a designated work time for projects and will be led by workshop officers that will develop plans to cater to your project's needs."
    },
    {
        question: "How can I ensure my project is accepted, do you all look for specific things in the application?",
        answer: "We don't necessarily look for specific things, but keep these in mind: Be specific as possible and show passion about your project. Reach out to our workshop officers to proof reach and give you tips!"
    },
]