import React from 'react'
import { FaInstagram } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa6";
import { motion } from "framer-motion"

const Footer = () => {
  return (
    <motion.footer 
        className="w-screen flex justify-between items-center p-6 bg-gradient-to-r from-[#7657A0] to-[#742C7F]"
        initial={{ opacity: 0, y: 25 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        transition={{ duration: 0.3, delay: 0.5 }}
    >
        <div>
            <a href="/">
                <h1 className="font-extrabold text-white text-xl">Texas A&M TechVenture Initative ©</h1>
            </a>
        </div>
        <div className="flex mr-5" >
            <motion.a href="https://www.instagram.com/tamutvi/" target="_blank" whileHover={{ scale: 1.1 }}>
                <FaInstagram className="mr-4 text-4xl text-white bg-gradient-to-r from-[#DD2A7B] to-[#F58529] rounded-xl" />
            </motion.a>
            <motion.a href="https://www.linkedin.com/company/texas-a-m-university-techventure-initiative/" target="_blank" whileHover={{ scale: 1.1 }}>
                <FaLinkedin className="text-4xl text-[#63c3ff]"/>
            </motion.a>
        </div>
    </motion.footer>
  )
}

export default Footer;
